<template>
  <div id="main">
    <div id="banner">
      <img class="banner-bg" :src='require("@/assets/img/about/responsibility/banner.png")'>
    </div>
    <div id="video">
      <div class="video-container" @mouseenter="isVideoHover = true" @mouseleave="isVideoHover = false">
        <video class="video" ref="myVideo" @click="playVideo" :src="videoPath" muted
               :poster="coverPath"
               @pause="playState(false)" @play="playState(true)">
        </video>
        <img v-if="!isVideoPlaying" class="playBtn" @click.stop="playVideo"
             :src='require("@/assets/img/product/t20/play_circle.png")'/>
        <img v-if="isVideoPlaying && isVideoHover" class="playBtn" @click.stop="pauseVideo"
             :src='require("@/assets/img/product/t20/pause_circle.png")'/>
      </div>
      <p class="video-title">盆底健康小屋</p>
      <p class="video-desc">从医院到居家无缝连接盆底康复</p>
      <div class="info-list">
        <div class="info-item">
          <img alt="" class="info-icon" :src='require("@/assets/img/about/responsibility/info-1.png")'/>
          <p class="info-title">专业盆底康复设备</p>
          <p class="info-desc">麦澜德医用级生物刺激反馈仪<br/>澜渟家用生物刺激反馈仪</p>
        </div>
        <div class="info-item">
          <img alt="" class="info-icon" :src='require("@/assets/img/about/responsibility/info-2.png")'/>
          <p class="info-title">盆底健康科普</p>
          <p class="info-desc">提供专业的盆底科普知识<br/>帮助妇女了解盆底健康的重要性</p>
        </div>
        <div class="info-item">
          <img alt="" class="info-icon" :src='require("@/assets/img/about/responsibility/info-3.png")'/>
          <p class="info-title">医院科室支持</p>
          <p class="info-desc">医院妇幼保健院与品牌共同运营<br/>对患者盆底进行检测、评估和治疗</p>
        </div>
      </div>
    </div>
    <div id="ip">
      <img class="ip-left" :src='require("@/assets/img/about/responsibility/ip-left.png")'>
      <img class="ip-right" :src='require("@/assets/img/about/responsibility/ip-right.png")'>
    </div>
    <div id="plan">
      <div class="plan-list">
        <div class="plan-item">
          <div class="plan-content">
            <div class="plan-title">西部女性盆底健康援助计划</div>
            <div class="plan-location">
              <img :src='require("@/assets/img/about/responsibility/location.png")'/>2024 年青海西宁站
            </div>
            <div class="plan-desc">2024 年 8 月 20 日，澜渟与麦澜德携手江苏省妇儿基金会向青海省妇幼保健院、大通县妇幼保健院捐赠超百万元的盆底康复治疗仪，并挂牌盆底健康小屋。</div>
            <div class="plan-btn" @click="goDetail" data-news="NEWS00013">了解更多<img :src='require("@/assets/img/about/responsibility/next.png")'/>
            </div>
          </div>
          <img class="plan-icon" alt="" :src='require("@/assets/img/about/responsibility/one.png")'/>
        </div>
        <div class="plan-item">
          <div class="plan-content">
            <div class="plan-title">西部女性盆底健康援助计划</div>
            <div class="plan-location">
              <img :src='require("@/assets/img/about/responsibility/location.png")'/>2023 年新疆伊利站
            </div>
            <div class="plan-desc">2023 年 9 月 19 日，澜渟与麦澜德携手江苏省妇儿基金会向伊犁哈萨克自治州友谊医院、伊宁县妇幼保健院，捐赠两台麦澜德医用级生物刺激反馈仪、百余台澜渟家用盆底生物刺激反馈仪。</div>
            <div class="plan-btn" @click="goDetail" data-news="NEWS00001">了解更多<img :src='require("@/assets/img/about/responsibility/next.png")'/>
            </div>
          </div>
          <img class="plan-icon" alt="" :src='require("@/assets/img/about/responsibility/two.png")'/>
        </div>
        <div class="plan-item">
          <div class="plan-content">
            <div class="plan-title">一起挺妈妈线上公益行动</div>
            <div class="plan-location">联合天猫健康、阿里健康等社交平台</div>
            <div class="plan-desc">2023 年 9 月，澜渟联合天猫电商平台发起了#一起挺妈妈#线上公益行动，通过社交媒体进行盆底健康知识的科普，并为产后存在盆底功能障碍疾病的女性群体免费提供盆底肌修复仪。</div>
            <div class="plan-btn" @click="goDetail" data-news="NEWS00006">了解更多<img :src='require("@/assets/img/about/responsibility/next.png")'/>
            </div>
          </div>
          <img class="plan-icon" alt="" :src='require("@/assets/img/about/responsibility/three.png")'/>
        </div>
      </div>
    </div>
    <div id="award">
      <div class="award-content">
        <img alt="" class="award-bg" :src='require("@/assets/img/about/responsibility/award.png")'>
        <div class="award-info">
          <div class="award-title">
            <img :src='require("@/assets/img/about/responsibility/flag.png")'/>
            公益践行奖
            <img :src='require("@/assets/img/about/responsibility/flag.png")'/>
          </div>
          <div class="award-desc">
            南京麦豆健康科技有限公司携澜渟品牌联合江苏省妇女儿童福利基金会发起的“我助妇儿康”关爱女性盆底健康援助计划线上+线下公益项目,荣膺第十三届公益节颁发的“年度公益践行奖”。
          </div>
          <div class="award-btn" @click="goDetail" data-news="NEWS00009">了解更多<img :src='require("@/assets/img/about/responsibility/next.png")'/>
          </div>
        </div>
      </div>
    </div>
    <div id="footer-content">
      <img class="footer-bg" :src='require("@/assets/img/about/responsibility/bottom-banner.png")'>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      coverPath: `//rightinhome.oss-cn-hangzhou.aliyuncs.com/train_prepare_cover/${process.env.NODE_ENV === 'prod' ? '' : 'test/'}website_responsibility.jpeg`,
      videoPath: `//rightinhome.oss-cn-hangzhou.aliyuncs.com/train_prepare_video/${process.env.NODE_ENV === 'prod' ? '' : 'test/'}website_responsibility.mp4`,
      isVideoPlaying: false,
      isVideoHover: false,
      isClickPause: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    setInterval(() => {
      this.pageScroll();
    }, 1000);
  },
  methods: {
    pageScroll() {
      if (this.isClickPause) {
        return;
      }
      // 判断 video是不是被 header sh
      const videoDom = document.querySelector('.video-container');
      const headerDom = document.querySelector('#top');
      if (videoDom && headerDom) {
        // videoDom 在 屏幕中间
        if (videoDom.getBoundingClientRect().top + videoDom.getBoundingClientRect().height / 2 > headerDom.getBoundingClientRect().height &&
          window.innerHeight - videoDom.getBoundingClientRect().top > videoDom.getBoundingClientRect().height / 2) {
          this.$refs.myVideo.play();
          this.isVideoPlaying = true;
        } else {
          this.$refs.myVideo.pause();
          this.isVideoPlaying = false;
        }
      }
    },
    playState(isPlay) {
      this.isVideoPlaying = isPlay;
      if (isPlay) {
        this.$refs.myVideo.setAttribute('controls', 'true');
      } else {
        this.$refs.myVideo.removeAttribute('controls');
      }
    },
    playVideo() {
      this.$refs.myVideo.muted = false;
      this.$refs.myVideo.play();
    },
    pauseVideo() {
      this.isClickPause = true;
      this.$refs.myVideo.pause();
    },
    goDetail(e) {
      this.$router.push({path: '/about/consultation/detail/' + e.target.dataset.news});
    },
  },
};
</script>

<style lang="scss" scoped>
#banner {
  position: relative;
  height: 4.0rem;
  background-color: #ffffff;

  > .banner-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

#video {
  padding: 0.8rem 3.6rem;
  background-color: #fff;

  > .video-container {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    > .video {
      width: 12rem;
      height: 6.75rem;
      border-radius: 0.2rem;
    }

    > .playBtn {
      position: absolute;
      width: 0.8rem;
    }
  }

  > .video-title {
    margin-top: 0.8rem;
    font-weight: 500;
    font-size: 0.44rem;
    line-height: 0.62rem;
    text-align: center;
  }

  > .video-desc {
    margin-top: 0.16rem;
    font-size: 0.32rem;
    line-height: 0.3rem;
    text-align: center;
  }

  > .info-list {
    margin-top: 0.8rem;
    display: flex;
    justify-content: space-between;

    > .info-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .info-icon {
        width: 3.86rem;
        height: 2.26rem;
      }

      > .info-title {
        margin-top: 0.24rem;
        font-size: 0.24rem;
        font-weight: 500;
        line-height: 0.34rem;
      }

      > .info-desc {
        margin-top: 0.16rem;
        color: #666;
        font-size: 0.16rem;
        line-height: 0.22rem;
        text-align: center;
      }
    }
  }
}

#ip {
  position: relative;
  height: 5.18rem;
  background: #EFEBFA;

  > .ip-left {
    position: absolute;
    left: 5.22rem;
    top: 1.35rem;
    width: 2.48rem;
  }

  > .ip-right {
    position: absolute;
    right: 4.5rem;
    top: 0.45rem;
    width: 5.38rem;
  }
}

#plan {
  background-color: #fff;
  padding: 0 4.5rem;

  .plan-list {
    > .plan-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8rem 0;
      border-bottom: 0.01rem solid #f6f6f6;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      &:last-child {
        border-bottom: none;
      }

      > .plan-content {
        width: 4.6rem;

        > .plan-title {
          font-weight: 500;
          font-size: 0.3rem;
          line-height: 0.42rem;
        }

        > .plan-location {
          margin-top: 0.16rem;
          height: 0.42rem;
          background: rgba(114, 84, 188, 0.2);
          border-radius: 10rem 10rem 10rem 10rem;
          padding: 0 0.24rem;
          display: flex;
          align-items: center;
          font-size: 0.24rem;
          line-height: 0.34rem;
          width: fit-content;

          > img {
            width: 0.24rem;
            height: 0.24rem;
            margin-right: 0.12rem;
          }
        }

        > .plan-desc {
          margin-top: 0.24rem;
          font-weight: 400;
          font-size: 0.16rem;
          color: #666666;
          line-height: 0.22rem;
        }

        > .plan-btn {
          cursor: pointer;
          margin-top: 0.24rem;
          border-radius: 1rem;
          border: 0.01rem solid #333333;
          width: fit-content;
          height: 0.48rem;
          padding: 0 0.24rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.2rem;
          line-height: 0.48rem;

          &:hover, &:active {
            border-color: $md-color-primary;
            color: $md-color-primary;

            > img {
              filter: none;
            }
          }

          > img {
            width: 0.24rem;
            height: 0.24rem;
            margin-left: 0.12rem;
            filter: grayscale(1) contrast(10);;
          }
        }
      }

      > .plan-icon {
        width: 5rem;
      }
    }
  }
}

#award {
  padding: 0.8rem 3.49rem 0.8rem 3.6rem;
  background-color: #FAFBFF;

  > .award-content {
    background: #fff;
    border-radius: 0.12rem;
    display: flex;

    > .award-bg {
      width: 6.28rem;
      height: 3.53rem;
    }

    > .award-info {
      padding: 0.53rem 1.2rem;

      > .award-title {
        font-weight: 500;
        font-size: 0.44rem;
        line-height: 0.62rem;
        display: flex;
        align-items: center;

        > img {
          width: 0.4rem;
          height: 0.4rem;

          &:last-child {
            transform: scaleX(-1);
          }
        }
      }

      > .award-desc {
        margin-top: 0.24rem;
        font-weight: 400;
        font-size: 0.16rem;
        color: #666666;
        line-height: 0.22rem;
      }

      > .award-btn {
        cursor: pointer;
        margin-top: 0.24rem;
        border-radius: 1rem;
        border: 0.01rem solid #333333;
        width: fit-content;
        height: 0.48rem;
        padding: 0 0.24rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.2rem;
        line-height: 0.48rem;

        &:hover, &:active {
          border-color: $md-color-primary;
          color: $md-color-primary;

          > img {
            filter: none;
          }
        }

        > img {
          width: 0.24rem;
          height: 0.24rem;
          margin-left: 0.12rem;
          filter: grayscale(1) contrast(10);;
        }
      }
    }
  }
}

#footer-content {
  position: relative;
  width: 100%;
  display: flex;

  > .footer-bg {
    width: 100%;
  }
}
</style>
