import { render, staticRenderFns } from "./responsibility.vue?vue&type=template&id=d1cc8766&scoped=true&"
import script from "./responsibility.vue?vue&type=script&lang=js&"
export * from "./responsibility.vue?vue&type=script&lang=js&"
import style0 from "./responsibility.vue?vue&type=style&index=0&id=d1cc8766&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1cc8766",
  null
  
)

export default component.exports